import TechnologyCard from "../../../../src/components/technology-card";
import ReactImg from "../../../../static/react.svg";
import Redux from "../../../../static/redux.svg";
import Node from "../../../../static/node.svg";
import Python from "../../../../static/python.svg";
import Swift from "../../../../static/swift.svg";
import NGINX from "../../../../static/nginx.svg";
import Git from "../../../../static/git.svg";
import Github from "../../../../static/github.svg";
import Jenkins from "../../../../static/jenkins.svg";
import CircleCi from "../../../../static/circleci.svg";
import Kubernetes from "../../../../static/kubernetes.svg";
import EC2 from "../../../../static/ec2.svg";
import Ember from "../../../../static/ember.svg";
import Java from "../../../../static/java.svg";
import Go from "../../../../static/golang.svg";
import { Grid, jsx } from "theme-ui";
import * as React from 'react';
export default {
  TechnologyCard,
  ReactImg,
  Redux,
  Node,
  Python,
  Swift,
  NGINX,
  Git,
  Github,
  Jenkins,
  CircleCi,
  Kubernetes,
  EC2,
  Ember,
  Java,
  Go,
  Grid,
  jsx,
  React
};