import React from "react"
import ProjectCard from "../components/project-card"
import TechnologyCard from "../components/technology-card"

const components = {
  // eslint-disable-next-line react/display-name
  ProjectCard: ({ link, title, bg, children }) => (
    <ProjectCard link={link} title={title} bg={bg}>
      {children}
    </ProjectCard>
  ),
  TechnologyCard: ({ img, children }) => (
    <TechnologyCard img={img}>{children}</TechnologyCard>
  ),
}

export default components
