/** @jsx jsx */
import { graphql, useStaticQuery } from "gatsby"
import { Image, Box, Text, useThemeUI, jsx } from "theme-ui"

const TechnologyCard = ({ img, children }) => {
  const { colorMode } = useThemeUI()
  const color = colorMode === "light" ? "white" : "black"
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        sx={{
          backgroundColor: color,
          border: `8px solid ${color}`,
          borderRadius: "50%",
          overflow: "hidden",
          minWidth: 100,
          height: 100,
          justifyContent: "center",
          zIndex: 1
        }}
      >
        <Image src={img} alt={children} sx={{ minWidth: "100%", height: "100%" }} />
      </Box>
      <Box sx={{
          backgroundColor: color,
          border: `8px solid ${color}`,
          borderTopRightRadius: 32,
          borderBottomRightRadius: 32,
          marginLeft: -50,
          minWidth: 275,
        //   height: "100%"
      }}>
        <Text
          sx={{
            display: "flex",
            fontStyle: "italic",
            justifyContent: "center",
            textAlign: "center",
            minWidth: "100%",
            margin: "auto",
            lineHeight: "48px",
            fontWeight: "600"
          }}
        >
          {children}
        </Text>
      </Box>
    </Box>
  )
}

export default TechnologyCard
